html, body {
    height: 100vh;
}

div {
    width: 100%;
    height: 100%;
}

div[role='presentation'] div, div[role='tooltip'] {
    height: auto;
    width: auto;
}

table {
    max-height: 100%;
    width: 100%;
}

table {
    overflow-y: auto;
}

::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: #424242;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.08);
}

::-webkit-scrollbar-corner {
    background: #424242;
}
